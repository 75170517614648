import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#002156",
        secondary: "#ffe001",
        accent: "#ed3825",
      },
    },
  },
  icons: {
    iconfont: "fa",
  },
};

export default new Vuetify(opts);
