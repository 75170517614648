<template>
  <v-carousel
    hide-delimiters
    cycle
    :show-arrows="false"
    class="grey lighten-3 review-carousel"
    interval="8000"
  >
    <div class="hashtag primary--text text-center mt-10 mb-n12">
      #KeepItPopUp
    </div>
    <v-carousel-item
      v-for="(r, i) in shuffledReviews"
      :key="i"
      :transition="false"
    >
      <v-container class="fill-height">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6">
            <v-rating
              v-model="r.rating"
              background-color="accent"
              class="text-center animate__animated animate__slideInLeft"
              color="accent"
              :large="$vuetify.breakpoint.mdAndUp"
              readonly
              :aria-label="r.rating + ' stars'"
            >
            </v-rating>
            <v-col cols="auto" class="mx-auto">
              <a
                :href="r.permalink"
                target="_blank"
                rel="noreferrer"
                style="text-decoration: none"
              >
                <h1 class="primary--text animate__animated animate__flipInX">
                  "{{ r.review }}"
                </h1>
              </a>
              <h3
                class="accent--text text-right animate__animated animate__slideInRight"
              >
                - {{ r.user }}
              </h3>
              <h4
                class="accent--text text-right animate__animated animate__slideInRight"
              >
                {{ r.city }}
              </h4>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "review-carousel",

  data() {
    return {
      reviews: [
        {
          rating: 5,
          user: "John P.",
          review:
            "...taste was unbelievably amazing. Everything was phenomenal!!! You need to try this place ASAP! Yum!",
          city: "Bloomington, IL",
          permalink:
            "https://www.yelp.com/biz/pop-up-chicken-shop-bloomington?hrid=cS-L62WiXbdBnbqZ_miQRA&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
        },
        {
          rating: 5,
          user: "Brian H.",
          review:
            "Best food I have ever had. I would give to 10 Stars if I could everything on the menus is a must try. Waffles are fantastic. If you like it hot ask for the poltergeist fries I dare you!!!!",
          city: "Columbia, IL",
          permalink:
            "https://www.yelp.com/biz/pop-up-chicken-shop-bloomington?hrid=am-VTVtCVbQRDO9yIvgYjg&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
        },
        {
          rating: 5,
          user: "Willis C.",
          review:
            "Delicious!!  We came from DC area to visit my best friend for a few days and this place has one of the best chickens I've ever eaten.  The price is just right!",
          city: "Owings Mills, MD",
          permalink:
            "https://www.yelp.com/biz/pop-up-chicken-shop-bloomington?hrid=OSShJ7D9lcOJDETswhTKWw&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
        },
        {
          rating: 5,
          user: "Stella W.",
          review:
            "As good as it gets!!!! OMG I'm so happy that we have such a great, unique, cheap, and delicious shop!",
          city: "Bloomington, IL",
          permalink:
            "https://www.yelp.com/biz/pop-up-chicken-shop-bloomington?hrid=DQSfFG402hfuBNs4ubTdXA&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
        },
        {
          rating: 5,
          user: "Brian D.",
          review:
            "#1 Nashville Hot Chicken around. If you don't like this place, you've got problems. 5 stars.",
          city: "Rockford, IL",
          permalink:
            "https://www.yelp.com/biz/pop-up-chicken-shop-bloomington?hrid=sMsROowLcPAthx2lIrn17w&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
        },
        {
          rating: 4,
          user: "Rudy C.",
          review:
            "Bravo on a job well done, Pop Up.  My only regret is that I couldn't sample more of your menu items.  I'm sure we will be back on our travels.",
          city: "San Francisco, CA",
          permalink:
            "https://www.yelp.com/biz/pop-up-chicken-shop-bloomington?hrid=6vtt8CRCv7chG1e4g_vrkA&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
        },
      ],
    };
  },

  computed: {
    shuffledReviews() {
      let array = this.reviews;

      for (var i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }

      return array;
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "EckhardtPosterItalicJNL";
  font-weight: 400;
  font-size: 1.33rem;
  @media (min-width: 960px) {
    font-size: 1.5rem;
  }
}

h3,
h4 {
  font-family: "Anton";
  font-weight: 400;
  opacity: 0.8;
}

.review-carousel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1875;
  background: url("https://res.cloudinary.com/dkkcmz7aj/image/upload/popup/grayscale_chicken.png");
  background-attachment: fixed;
}

.hashtag {
  font-family: "Kanit";
  font-size: 2.5rem;
  letter-spacing: 0.25rem;
}
</style>
