<template>
  <div class="accent" id="app-header" v-intersect="onHeaderIntersect">
    <v-container fluid class="accent px-0 pb-0">
      <v-row
        class="accent pb-3"
        align="center"
        justify="center"
        justify-lg="space-around"
        no-gutters
      >  
        <v-col cols="5" md="2" order="5" order-lg="0" class="mt-2">
          <v-card flat tile color="accent" min-width="320">
            <v-row justify="center" align="center" no-gutters>
              <v-col cols="2" class="d-none d-md-inline text-center">
                <v-avatar
                  color="secondary"
                  class="mr-md-2"
                  @click="goLocation"
                  style="cursor: pointer"
                >
                  <v-icon color="primary">fas fa-map-marker-alt</v-icon> 
                </v-avatar>
              </v-col>
              <v-col cols="10" lg="12" class="text-center">
                <address
                  class="secondary--text subtitle font-weight-bold"
                  @click="goLocation"
                  style="cursor: pointer"
                >
                  409 N Hershey Rd,<br />
                  Bloomington, IL 61704
                </address>
                <v-row no-gutters justify="center">
                  <v-col cols="auto" lg="12">
                    <v-icon color="primary" small class="pr-1"
                      >fas fa-phone</v-icon
                    >
                    <a href="tel:+13098273999" class="popup-text pr-3"
                      >(309) 827-3999</a
                    >
                  </v-col> 
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="5" md="2" order="5" order-lg="0" class="mt-2">
          <v-card flat tile color="accent" min-width="320">
            <v-row justify="center" align="center" no-gutters>
              <v-col cols="2" class="d-none d-md-inline text-center">
                <v-avatar
                  color="secondary"
                  class="mr-md-2"
                  @click="goLocation"
                  style="cursor: pointer"
                >
                  <v-icon color="primary">fas fa-map-marker-alt</v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="10" lg="12" class="text-center">
                <address
                  class="secondary--text subtitle font-weight-bold"
                  @click="goLocation"
                  style="cursor: pointer"
                >
                  800 SW Adams St Suite 106,<br />
                  Peoria, IL 61616
                </address>
                <v-row no-gutters justify="center">
                  <v-col cols="auto" lg="12">
                    <v-icon color="primary" small class="pr-1"
                      >fas fa-phone</v-icon
                    >
                    <a href="tel:+13098392010" class="popup-text pr-3"
                      >(309) 839-2010</a
                    >
                  </v-col> 
                </v-row> 
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" lg="4" order="0" order-lg="5">
          <v-card class="accent secondary--text" flat tile min-width="320px">
            <v-row
              class="text-center accent"
              justify="center"
              align="center"
              no-gutters
            >
              <v-col cols="12" sm="auto">
                <v-img
                  src="https://res.cloudinary.com/dkkcmz7aj/image/upload/w_90/popup/popup_logo_badge"
                  width="90px"
                  class="mx-auto mx-sm-2"
                ></v-img>
              </v-col>
              <v-col cols="12" sm="auto">
                <h1 class="pr-sm-2">POP-UP</h1>
              </v-col>
            </v-row>

            <v-row
              align="center"
              justify="center"
              class="text-center"
              no-gutters
            >
              <v-col cols="auto" class="primary py-0" style="width: 320px">
                <h4 class="text-uppercase">Chicken Shop</h4>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="auto" md="4" order="10" class="mt-2">
          <v-card flat tile color="accent" min-width="320">
            <v-row align="center" justify="center" no-gutters>
              <v-col
                cols="auto"
                lg="12"
                class="pt-md-0 d-none d-md-inline text-center"
              >
                <v-avatar color="secondary" class="mr-2">
                  <v-icon color="primary">fas fa-clock</v-icon>
                </v-avatar>
              </v-col>
              <v-col
                class="popup-text secondary--text pt-0 text-center"
                cols="auto"
              >
                <br/>
                11 AM - 9 PM<br/>
                Sunday - Thursday<br/>
                <br/>
                11 AM - 10 PM<br/>
                Friday - Saturday<br/>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <app-bar></app-bar>
    </v-container>
  </div>
</template>

<script>
import AppBar from "@/components/nav/AppBar.vue";

export default {
  name: "app-header",

  components: { AppBar },

  data() {
    return {
      scrollOpions: {
        duration: 500,
        offset: 64,
        easing: "easeInOutCubic",
      },
    };
  },

  computed: {
    appBar() {
      return this.$store.state.ui.appBar;
    },
  },

  methods: {
    onHeaderIntersect(en) {
      if (en[0].isIntersecting) {
        this.$store.commit("ui/appBarFalse");
      } else {
        this.$store.commit("ui/appBarTrue");
      }
    },
    goLocation() {
      let currRoute = this.$route.path;

      if (currRoute != "/" && currRoute != "/location") {
        this.$router.push("/");
        setTimeout(() => {
          this.$vuetify.goTo("#location", this.options);
        }, 500);
      } else {
        this.$vuetify.goTo("#location", this.options);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  background-color: #ed3825;
}
h1 {
  font-family: "DayPosterBlack", cursive;
  font-size: 64px;
  letter-spacing: 2px;
  text-shadow: -4px 4px #002156;
  transform: scaleY(1.2);
}

h4 {
  font-family: "EckhardtPosterItalicJNL", sans-serif;
  font-size: 22px;
  letter-spacing: 0.4rem;
}

h5 {
  font-family: "EckhardtPosterItalicJNL", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.25rem;
}

/* button:hover {
        background-color: #002156 !important;
        transition: all .25s;
    }

    button {
        transition: all .25s;
    } */

address,
.popup-text {
  font-style: normal;
  font-family: "DayPosterBlack";
  letter-spacing: 1px;
  text-shadow: -2px 2px #002156;
  font-size: 18px;
  line-height: 1.75rem;
}

a.popup-text {
  color: #ffe001;
  text-decoration: none;
}

a.popup-text:hover {
  color: #002156;
  text-shadow: none;
  text-decoration: underline solid #002156;
}

.corner-ribbon {
  width: 350px;
  background: #002156;
  position: absolute;
  top: 55px;
  left: -120px;
  /* left: auto; */
  font-family: "EckhardtPosterItalicJNL";
  font-size: 1.15rem;
  text-align: center;
  line-height: 1.5rem;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  padding-bottom: 2px;
  z-index: 1;
}
</style>
