<template>
  <v-parallax
    src="https://res.cloudinary.com/dkkcmz7aj/image/upload/popup/hero_tray.jpg"
  >
    <v-overlay
      absolute
      :value="covidAlert"
      opacity="0.75"
      class="animate__animated"
      :class="covidAlert ? '' : 'animate__zoomOut'"
    >
      <v-alert
        border="top"
        type="warning"
        color="primary"
        prominent
        :value="covidAlert"
        elevation="12"
        class="animate__animated"
        :class="covidAlert ? 'animate__zoomIn' : 'animate__zoomOut'"
      >
        <v-row align="center" justify="center">
          <v-col cols="auto">
            <div class="text-h6">
              Due to COVID-19 we are currently closed for dine-in, and are
              accepting curbside pickup orders only.
            </div>
          </v-col>
          <v-col cols="auto">
            <v-btn
              to="curbside"
              x-large
              color="secondary"
              class="mr-3 black--text"
            >
              Order Curbside
            </v-btn>
            <v-btn
              x-large
              color="white"
              class="black--text"
              @click="covidAlert = false"
              >Dismiss</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </v-overlay>
    <v-row align="center" justify="space-between">
      <v-col cols="12" md="6">
        <v-col cols="12" class="title text-center">
          <v-row align="center" justify="center">
            <sequential-entrance delay="800">
              <v-col cols="12">
                <h2>For weekly specials, <br />desserts, events, and more</h2>
              </v-col>
              <v-col cols="12">
                <v-btn
                  x-large
                  color="secondary"
                  class="primary--text ma-2"
                  href="//www.facebook.com/popupchickenshop"
                  target="_blank"
                  rel="noreferrer"
                >
                  <v-icon large class="mr-3">mdi-facebook</v-icon> Follow us on
                  Facebook
                  <v-icon class="ml-3 secondary--text text--darken-4"
                    >mdi-open-in-new</v-icon
                  ></v-btn
                >
              </v-col>
              <v-col cols="12">
                <h2>Or</h2>
              </v-col>
              <v-col cols="12">
                <v-btn
                  x-large
                  color="primary"
                  class="secondary--text"
                  href="https://www.toasttab.com/pop-up-chicken-shop-409-n-hershey/rewardsSignup"
                  target="_blank"
                >
                  Sign-Up for our Rewards Program!
                  <v-icon class="ml-3 secondary--text text--darken-3"
                    >mdi-arrow-down</v-icon
                  ></v-btn
                >
              </v-col>
            </sequential-entrance>
          </v-row>
        </v-col>
      </v-col>

      <v-col
        cols="12"
        md="auto"
        class="d-none d-md-block mx-auto text-center animate__animated animate__fadeInLeft animate__delay-3s"
      >
        <!-- <v-card> -->
        <!-- <v-card-title class="primary secondary--text">
            <h4>The Latest from Us on Facebook</h4>
          </v-card-title> -->
        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fpopupchickenshop&tabs=timeline&width=450&height=400&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
          width="450"
          height="400"
          style="border: none; overflow: hidden"
          scrolling="no"
          frameborder="0"
          allowTransparency="true"
          allow="encrypted-media"
          title="The Latest from Us on Facebook"
        ></iframe>
        <!-- </v-card> -->
      </v-col>
    </v-row>
  </v-parallax>
</template>
<script>
export default {
  name: "app-hero",

  data() {
    return {
      covidAlert: false,
      scrollOptions: {
        offset: 53,
      },
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  },
};
</script>
<style lang="scss">
@import "~animate.css/source/_base.css";
@import "~animate.css/source/fading_entrances/fadeInLeft.css";

h2 {
  font-family: "EckhardtPosterItalicJNL";
  color: #ffe001;
  text-shadow: -1px 1px 5px #000;
  letter-spacing: 0.25rem;
}
h4 {
  font-family: "EckhardtPosterItalicJNL";
  letter-spacing: 0.125em;
}
</style>
